import React, { useState, useEffect, useLayoutEffect } from 'react';
import { LocaleProvider } from '../context/Locale';
import '../styles/main.css';
// import Banner from './Banner';
import Header from './Header';
import Footer from './Footer';
import Seo from './SEO';
import LogoWhite from '../svg/logoWhite.svg';
import useSound from 'use-sound';
import Twinkle from '../sounds/twinkle.mp3';

import Popup from './Popup';

function Layout({ children, location, pageContext: { locale } }) {
  // hideChrome
  const { pathname } = location;
  const isCart =
    (pathname && pathname.includes('cart')) ||
    pathname.includes('checkout') ||
    pathname.includes('wip') ||
    pathname.includes('restock');

  const showChrome = !isCart;

  // intro animation
  const [showIntro, setShowIntro] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  // Nav scroll styling
  const [showUnderline, setShowUnderline] = useState(false);
  useLayoutEffect(() => {
    const onScroll = () => {
      const posChecker = window.scrollY > 0;
      setShowUnderline(posChecker);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // Theme switcher
  const themes = [
    'gate',
    'janoviki',
    'moody',
    'dark',
    'club',
    'OG',
    'warm',
    'oldComputer',
  ];

  const [theme, setTheme] = useState('oldComputer');
  const [twinkle] = useSound(Twinkle);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
      setTheme('gate');
    } else {
      setTheme('oldComputer');
    }
  }, []);

  const themeSwitcher = () => {
    setTheme(themes[(themes.indexOf(theme) + 1) % themes.length]);
    // localStorage.setItem('theme', theme);
    twinkle();
  };

  // Pop up
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <main className={`${theme}`}>
      <Seo />
      <LocaleProvider locale={locale} location={location}>
        {showIntro ? (
          <div
            className="bg-white text-primary fade-out"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '902',
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '4000px',
            }}
            onClick={() => setShowIntro(false)}
            onKeyDown={() => setShowIntro(false)}
            role="button"
            tabIndex={0}
          >
            <LogoWhite />
          </div>
        ) : null}
        {showChrome ? (
          <div
            className={
              showUnderline
                ? 'border-primary border-b-0 md:border-b justify-between bg-white nav'
                : 'justify-between bg-white nav'
            }
          >
            {/* <div className="">
              <Banner />
            </div> */}
            <div className="border border-t border-l border-r border-primary sm:border-t-0 sm:border-l-0 sm:border-b md:border-b-0 sm:border-r-0">
              {/* {isOpen ? <Popup handleClose={() => setIsOpen(!isOpen)} /> : null} */}
              <Header
                themeSwitch={
                  <span
                    className="ml-8 cursor-pointer text-primary hover:opacity-50 animate-wiggle"
                    onClick={themeSwitcher}
                    title="shuffle theme"
                    onKeyDown={themeSwitcher}
                    tabIndex={0}
                    role="button"
                  >
                    🧸
                  </span>
                }
                {...location}
              />
            </div>
          </div>
        ) : null}
        {/* mt-16 lg:mt-12 BANNER */}
        <div className="container mx-auto -mt-1 -pt-px lg:py-8">{children}</div>
        {showChrome ? <Footer /> : null}
      </LocaleProvider>
    </main>
  );
}

export default Layout;
