import React from 'react';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';
import Arrow from '../svg/arrow.svg';

function Emails({ from }) {
  const [pop] = useSound(Switch);
  return (
    <form
      name={from}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/thankyou"
      className="flex flex-row"
    >
      <input type="hidden" name="form-name" value={from} />

      <div className="flex w-full">
        <input
          type="email"
          htmlFor="email address"
          //placeholder styling in .css
          placeholder="email address"
          defaultValue="email address"
          onFocus={(e) => (e.target.value = '')}
          name="email"
          className="border-l-0 border-white bg-primary email focus:outline-none"
        />

        <button
          onClick={pop}
          type="submit"
          className="relative p-6 -ml-px text-sm text-white border border-l-0 border-white footer-btn focus:outline-none"
          aria-label="Submit button"
        >
          <Arrow />
        </button>
      </div>
    </form>
  );
}

export default Emails;

// import React, { useState } from 'react';
// import useSound from 'use-sound';
// import Switch from '../sounds/switch.mp3';
// import Arrow from '../svg/arrow.svg';

// function Emails({ from }) {
//   const [pop] = useSound(Switch);

//   const [root, setRoot] = useState('');
//   const [domain, setDomain] = useState('');
//   const [tail, setTail] = useState('');

//   const [helper, setHelper] = useState(false);

//   // combine root, domain, and tail to form email address helper text
//   const emailHelper = `${root}@${domain}${tail}`;

//   return (
//     <form
//       name={from}
//       method="post"
//       data-netlify="true"
//       data-netlify-honeypot="bot-field"
//       action="/thankyou"
//       className="flex flex-row"
//     >
//       <input type="hidden" name="form-name" value={from} />
//       <section className="w-full">
//         <div className="flex">
//           <input
//             type="email"
//             htmlFor="email address"
//             placeholder="email address"
//             defaultValue="email address"
//             onFocus={(e) => (e.target.value = '')}
//             name="email"
//             value={emailHelper}
//             onChange={(e) => {
//               setRoot(e.target.value);
//             }}
//             className="border-l-0 email focus:outline-none"
//             onFocus={() => setHelper(true)}
//           />

//           <button
//             onClick={pop}
//             type="submit"
//             className="relative p-6 -ml-px text-sm border border-l-0 border-white footer-btn focus:outline-none"
//             aria-label="Submit button"
//           >
//             <Arrow fill="grey" />
//           </button>
//         </div>
//         {helper ? (
//           <>
//             {' '}
//             <div className="flex justify-between my-4" id="helper">
//               <button
//                 className="w-full outline-btn-small"
//                 type="button"
//                 value="hotmail"
//                 onClick={() => setDomain('hotmail')}
//               >
//                 hotmail
//               </button>
//               <button
//                 className="w-full mx-4 outline-btn-small"
//                 type="button"
//                 value="gmail"
//                 onClick={() => setDomain('gmail')}
//               >
//                 gmail
//               </button>
//               <button
//                 className="w-full outline-btn-small"
//                 type="button"
//                 name="yahoo"
//                 onClick={() => setDomain('yahoo')}
//               >
//                 yahoo
//               </button>
//             </div>
//             <div className="flex justify-between my-4">
//               <button
//                 className="w-full mr-2 outline-btn-small"
//                 type="button"
//                 value="hotmail"
//                 onClick={() => setTail('.co.uk')}
//               >
//                 .co.uk
//               </button>
//               <button
//                 className="w-full ml-2 outline-btn-small"
//                 type="button"
//                 value=".com"
//                 onClick={() => setTail('.com')}
//               >
//                 .com
//               </button>
//             </div>
//             <button className="w-full primary-btn-small">send it</button>{' '}
//           </>
//         ) : null}
//       </section>
//     </form>
//   );
// }

// export default Emails;
