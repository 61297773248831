import React from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';
import socialCardImage from '../img/socialCard.jpg';

function SEO({
  children,
  pageDescription,
  pageTitle,
  pageUrl,

  productPrice,
  productId,
  currency,
  category,
  remoteId,
  productDescription,
  url,
}) {
  const {
    site: {
      siteMetadata: { description: siteDescription, siteUrl, title: siteTitle },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          siteUrl
          title
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <Helmet
        defaultTitle={siteTitle}
        htmlAttributes={{ lang: 'en' }}
        titleTemplate={pageTitle ? `%s | ${siteTitle}` : 'deadnice'}
      >
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription || siteDescription} />
        <meta property="og:url" content={pageUrl || siteUrl} />
        <meta
          property="og:description"
          content={pageDescription || siteDescription}
        />
        <meta
          property="og:title"
          content={`${pageTitle || siteDescription} | ${siteTitle}`}
        />

        <meta property="og:image" content={withPrefix(socialCardImage)} />

        <meta
          property="twitter:title"
          content={`${pageTitle || siteDescription} | ${siteTitle}`}
        />
        <meta property="twitter:card" content="summary_large_image" />

        <meta property="twitter:image" content={withPrefix(socialCardImage)} />

        {/* facebook data */}
        <meta property="product:availability" content="in stock" />
        <meta property="product:price:amount" content={productPrice} />
        <meta property="product:price:currency" content="GBP" />
        <meta property="product:retailer_item_id" content={productId} />
        <meta property="product:category" content="1604" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={productDescription} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={withPrefix(socialCardImage)} />
        <meta property="product:brand" content="deadnice" />
        <meta property="product:condition" content="new" />
        <meta property="product:item_group_id" content={category} />
        <meta
          property="product:retailer_item_id"
          content={`deadnice_${remoteId}`}
        />
        <meta
          property="product_group"
          content="Apparel & Accessories > Clothing"
        />
      </Helmet>

      {children}
    </React.Fragment>
  );
}

export default SEO;
