import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby';

class Example extends React.Component {
  render() {
    const Burger = () => {
      return (
        <div className="text-primary">
          <svg
            width="24"
            height="14"
            viewBox="0 0 24 14"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path d="M0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V2H0V1Z" />
            <path d="M0 12H24V13C24 13.5523 23.5523 14 23 14H1C0.447716 14 0 13.5523 0 13V12Z" />
            <rect y="6" width="24" height="2" />
          </svg>
        </div>
      );
    };

    const Cross = () => {
      return (
        <div className="text-primary">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.707107 17.2929C0.316582 16.9024 0.316582 16.2692 0.707107 15.8787L16.2635 0.322331C16.654 -0.0681932 17.2871 -0.0681935 17.6777 0.322331C18.0682 0.712855 18.0682 1.34602 17.6777 1.73654L2.12132 17.2929C1.7308 17.6834 1.09763 17.6834 0.707107 17.2929Z"
              fill="currentColor"
            />
            <path
              d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L17.2635 15.8492C17.654 16.2398 17.654 16.8729 17.2635 17.2635C16.8729 17.654 16.2398 17.654 15.8492 17.2635L0.292893 1.70711C-0.0976309 1.31658 -0.0976312 0.683417 0.292893 0.292893Z"
              fill="currentColor"
            />
          </svg>
        </div>
      );
    };

    return (
      <Menu
        right
        isOpen={false}
        width={'100%'}
        customBurgerIcon={<Burger />}
        customCrossIcon={<Cross />}
        className="items-center justify-center mx-auto font-serif text-3xl outline-none"
      >
        <Link className="py-6 hover:text-primary " to="/">
          home
        </Link>
        <Link to="/collections" className="py-6 hover:text-primary">
          collections
        </Link>
        <Link to="/categories" className="py-6 hover:text-primary">
          categories
        </Link>
        <Link to="/sustainability" className="py-6 hover:text-primary">
          sustainability
        </Link>
        <Link to="/lookbook" className="py-6 hover:text-primary">
          lookbook
        </Link>
        <Link className="py-6 hover:text-primary" to="/about">
          about
        </Link>
      </Menu>
    );
  }
}

export default Example;
