import 'isomorphic-fetch';
import 'react-toastify/dist/ReactToastify.css';
import './src/styles/main.css';
import React from 'react';
import { Link } from 'gatsby';
import { CartProvider } from 'react-use-cart';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { ToastContainer } from 'react-toastify';

import StripeProvider from './src/components/StripeProvider';
import Layout from './src/components/Layout';
import {
  handleItemAdded,
  handleItemUpdated,
  handleItemRemoved,
} from './src/utils/cart-helpers';

// need to sort theme for

const toastOptions = {
  position: 'top-right',
  draggable: true,
  toastClassName:
    'border border-primary bg-primary font-mono text-sm text-primary font-bold text-center p-4 shadow-none m-8',
  progressClassName: 'h-0',
  closeButton: false,
  autoClose: 2000,
};

const client = new GraphQLClient({
  url: '/.netlify/functions/graphql',
});

// const randomCartId = () => Math.random().toString(36).substring(7);

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <StripeProvider>
      <ClientContext.Provider value={client}>
        <CartProvider
          // id={randomCartId()}
          onItemAdd={handleItemAdded}
          onItemUpdate={handleItemUpdated}
          onItemRemove={handleItemRemoved}
        >
          {element}
        </CartProvider>
        <Link to="/cart">
          <ToastContainer {...toastOptions} />
        </Link>
      </ClientContext.Provider>
    </StripeProvider>
  );
};
