import React from 'react';
import { Link } from 'gatsby';
import { useCart } from 'react-use-cart';

import Logo from '../svg/logo.svg';
import Ham from '../components/Hamburger';

import useSound from 'use-sound';
import Search from '../sounds/search.mp3';
import Cash from '../sounds/cash.mp3';

function Header({ pathname, themeSwitch }) {
  const { isEmpty } = useCart();
  const [cash] = useSound(Cash, { volume: 0.1 });
  const [search] = useSound(Search);

  return (
    <header className="container items-center justify-between flex-grow block w-full px-4 mx-auto text-primary md:px-0">
      <div className="w-full px-2 pt-8 pb-5 lg:px-0">
        <nav className="flex items-center justify-between">
          <Link aria-label="Home" to="/">
            <Logo />
          </Link>

          <div className="flex items-center">
            <ul className="hidden md:mx-auto md:block md:flex-grow md:ml-4">
              <li className="block my-4 md:inline-block md:my-0">
                <Link
                  to="/collections"
                  className="px-3 py-2 text-sm font-medium text-primary hover:opacity-50 hover:rounded-full"
                >
                  collections
                </Link>
              </li>
              •
              <li className="block my-4 md:inline-block md:my-0">
                <Link
                  to="/categories"
                  className="px-3 py-2 text-sm font-medium text-primary hover:opacity-50 hover:rounded-full"
                >
                  categories
                </Link>
              </li>
              •
              <li className="block my-4 md:inline-block md:my-0">
                <Link
                  to="/about"
                  className="px-3 py-2 text-sm font-medium text-primary hover:opacity-50 hover:rounded-full"
                >
                  about us
                </Link>
              </li>
              •
            </ul>
            {themeSwitch}
            <Link
              to="/search"
              className="ml-8 text-primary hover:opacity-50 "
              onClick={search}
              aria-label="Search"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="w-4 fill-current"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
              </svg>
            </Link>

            <Link
              to="/cart"
              className="relative flex items-center ml-8"
              aria-label="Cart"
              onClick={cash}
            >
              {!isEmpty && (
                <span className="absolute top-0 right-0 w-2 h-2 -mt-1 -mr-2 rounded-full bg-secondary animate-pulse"></span>
              )}

              <span className="text-primary hover:opacity-50 ">
                <svg
                  className="w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M4 2h16l-3 9H4a1 1 0 1 0 0 2h13v2H4a3 3 0 0 1 0-6h.33L3 5 2 2H0V0h3a1 1 0 0 1 1 1v1zm1 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
                </svg>
              </span>
            </Link>

            <div className="ml-8 sm:block md:invisible md:hidden">
              <div className="flex text-primary ham ">
                <Ham />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
