import React from 'react';
import { Link } from 'gatsby';
import Email from '../components/Emails';

import useSound from 'use-sound';
import Fb from '../sounds/fb.mp3';
import Tw from '../sounds/tweet.mp3';
import In from '../sounds/insta.mp3';
import Chime from '../sounds/chime.mp3';
import Yawn from '../sounds/yawn.mp3';
import Cut from '../sounds/cut.mp3';

import Instagram from '../svg/socials/instagram.svg';
import Twitter from '../svg/socials/twitter.svg';
import LinkedIn from '../svg/socials/linkedin.svg';
import Pinterest from '../svg/socials/pinterest.svg';
import Youtube from '../svg/socials/youtube.svg';
import Book from '../svg/socials/book.svg';

function Footer() {
  const [facebook] = useSound(Fb, { volume: 0.6 });
  const [twitter] = useSound(Tw);
  const [instagram] = useSound(In, { volume: 0.75 });
  const [chime] = useSound(Chime, { volume: 0.25 });
  const [yawn] = useSound(Yawn, { volume: 0.05 });
  const [cut] = useSound(Cut, { volume: 0.4 });

  return (
    <div className="flex items-center md:h-full bg-primary md:px-24 lg:px-32 xl:px-48">
      <div className="container flex flex-col w-full p-8 mx-auto text-white md:flex-row">
        <div className="order-last p-4 md:order-first md:w-1/2">
          <h4 className="mb-2 text-4xl font-bold leading-tight">go peep</h4>
          <div className="flex flex-col justify-between h-full items-base">
            <ul className="py-4 text-color">
              <a
                href="http://instagram.com/deadnice.co"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm"
                onClick={instagram}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <Instagram />
                  </li>
                  <p className="text-base">instagram</p>
                </div>
              </a>
              <a
                href="https://fb.me/deadnice.co"
                target="_blank"
                className="text-sm hover:opacity-50"
                rel="noopener noreferrer"
                onClick={facebook}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <Book />
                  </li>
                  <p className="text-base">facebook</p>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/deadnice"
                target="_blank"
                className="text-sm hover:opacity-50"
                rel="noopener noreferrer"
                onClick={facebook}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <LinkedIn />
                  </li>
                  <p className="text-base">linkedin</p>
                </div>
              </a>
              <a
                href="https://twitter.com/deadniceco"
                target="_blank"
                className="text-sm hover:opacity-50"
                rel="noopener noreferrer"
                onClick={twitter}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <Twitter />
                  </li>
                  <p className="text-base">twitter</p>
                </div>
              </a>

              <a
                href="https://www.pinterest.co.uk/deadniceco/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm"
                onClick={instagram}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <Pinterest />
                  </li>
                  <p className="text-base">pinterest</p>
                </div>
              </a>

              <a
                href="https://www.youtube.com/channel/UCf1e3j4O7mIotRTTvY47e9w"
                target="_blank"
                className="text-sm hover:opacity-50"
                rel="noopener noreferrer"
                onClick={cut}
              >
                <div className="flex flex-row py-3 hover:opacity-50">
                  <li className="my-1">
                    <Youtube />
                  </li>
                  <p className="text-base">youtube</p>
                </div>
              </a>
            </ul>
            <br />
            <h4 className="text-4xl font-bold leading-tight hover:opacity-50">
              <Link to="/lookbook" onClick={instagram}>
                lookbook →
              </Link>
            </h4>
            <br />
            <h4 className="text-4xl font-bold leading-tight hover:opacity-50">
              <Link to="/sustainability" onClick={chime}>
                sustainability →
              </Link>
            </h4>
            <br />
            <h4 className="text-4xl font-bold leading-tight hover:opacity-50">
              <Link to="/stuff" onClick={yawn}>
                boring stuff →
              </Link>
            </h4>
            <br />
            <p className="py-16 text-sm text-white opacity-80">
              2020 inc. all rights reserved. deadnice™️
            </p>
          </div>
        </div>
        <div className="order-first w-full p-4 md:w-1/2">
          <h4 className="mb-4 text-4xl font-bold leading-tight">
            drop the addy
          </h4>
          <div className="pt-4 pb-16">
            <Email from="footer" />
          </div>
          <div>
            <h4 className="mb-2 text-4xl font-bold">contact us</h4>
            <p className="mb-3 leading-6 md:mb-0 ">
              <a
                className="hover:opacity-50 opacity-80"
                href="mailto:hello@deadnice.co"
              >
                hello@deadnice.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
