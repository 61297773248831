exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout/success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lookbook-js": () => import("./../../../src/pages/lookbook.js" /* webpackChunkName: "component---src-pages-lookbook-js" */),
  "component---src-pages-restock-js": () => import("./../../../src/pages/restock.js" /* webpackChunkName: "component---src-pages-restock-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-stuff-js": () => import("./../../../src/pages/stuff.js" /* webpackChunkName: "component---src-pages-stuff-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-wip-js": () => import("./../../../src/pages/wip.js" /* webpackChunkName: "component---src-pages-wip-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/CollectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/ProductsPage.js" /* webpackChunkName: "component---src-templates-products-page-js" */)
}

