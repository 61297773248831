module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"deadnice","short_name":"deadnice","description":"design—led, eco—friendly clothing co.","lang":"en","display":"fullscreen","icon":"src/svg/favicon-logo.svg","start_url":"/","background_color":"#000","scope":"/","theme_color":"#000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c56b9410782e8333dcd85094efd77023"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"280418509883699"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
