module.exports = [
  {
    default: true,
    path: `en`,
    label: `English`,
    locale: `en-GB`,
  },
  {
    path: `de`,
    label: `German`,
    locale: `de-DE`,
  },
];
